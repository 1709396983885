/* Services.css */
.services {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f9f9f9;
  }
  
  .services-content {
    width: 100%;
    text-align: center;
  }
  
  .services-banner {
    background-color: #000;
    max-height: 320px;
    width: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0 40px 0;
    overflow: hidden;
    position: relative;
  }
  .services__card-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 1080px;
    gap: 20px;
    background-color: #D5D5D5;
    padding: 50px 0 40px 0;
  }
  .services__title {
    color: #CF0000;
    font-size: 2.5rem;
    margin: 0;
  }

.services__list-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #000;
    color: white;
    position: relative;
    overflow: hidden;
    width: 622px;
  }

  .services__list {
    width: 50%;
    font-size: 1.2rem;
    position: relative;
    display: flex;
    justify-content: start;
    animation: slideAcross 22s linear infinite; 
    white-space: nowrap; 
    padding: 0;
  }

  .services__list-items {
    margin-left: 30px;
  }
  
  @keyframes slideAcross {
    0% {
      transform: translateX(0); /* Start at the original position */
    }
    100% {
      transform: translateX(-325%); /* Move to the midpoint of duplicated content */
    }
  }
  .services__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .services__card {
    background-color: transparent;
    border-radius: 10px;
    width: 300px;
    text-align: left;
  }
  
  .services__card-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .services__text {
    font-size: 1rem;
    color: #000;
  }

  .services__image {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 10px;
  }