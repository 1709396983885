/* Header.css */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px 10px 40px;
  background-color: #d5d5d5; /* You can change this to any color you like */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: #000 1px solid;
}

.header__logo-img {
  height: 120px;
  width: auto;
  border-radius: 50%;
  margin: 0;
}

.header__nav {
  display: flex;
}

.header__nav-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding-right: 30px;
  padding-left: 0;
}

.header__nav-item {
  margin-left: 30px;
  font-size: 22px;
  font-family: "Oswald", sans-serif;
}

.header__nav-item a {
  text-decoration: none;
  color: #000; /* Change this color to match your design */
  font-weight: 700; /* Adjust font weight as needed */
  transition: color 0.3s;
}

.header__nav-item a:hover {
  color: #00158e; /* Change this to a hover color of your choice */
}

.header__contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
}

.header__contact-line-1 {
  display: flex;
  font-family: "Oswald", sans-serif;
  color: black;
  font-weight: 700;
  margin: 0;
  padding: 0 0 5px 0;
  font-size: 24px;
}

.header__contact-line-2 {
  display: flex;
  align-items: center;
  font-family: "Oswald", sans-serif;
  color: #cf0000;
  font-weight: 600;
  font-size: 18px;
  margin: 0;
}

.header__contact-line-3 {
  display: flex;
  align-items: center;
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #00158e;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  text-decoration: none;
}

.header__contact-line-3:hover {
  cursor: pointer;
  opacity: 60%;
}

.header__contact-icon {
  font-size: 24px;
  height: 28px;
  width: 18px;
  margin-right: 5px;
}

.header__facebook-icon {
  font-size: 24px;
  height: 28px;
  width: 20px;
  margin-right: 5px;
}

/* Media Queries for Mobile Interface */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    padding: 10px 20px;
  }

  .header__logo-img {
    height: 80px;
    margin-bottom: 10px;
  }

  .header__nav {
    flex-direction: column;
    align-items: center;
  }

  .header__nav-list {
    flex-direction: column;
    padding-right: 0;
  }

  .header__nav-item {
    margin-left: 0;
    margin-bottom: 10px;
    font-size: 18px;
  }

  .header__contact-info {
    align-items: flex-start;
  }

  .header__contact-line-1 {
    font-size: 20px;
  }

  .header__contact-line-2,
  .header__contact-line-3 {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 10px;
  }

  .header__logo-img {
    margin-top: 10px;
    height: 90px;
    width: 90px;
  }

  .header__nav-item {
    font-size: 16px;
    padding: 0 14px 0 14px;
    font-weight: 800;
    font-family: "Oswald", sans-serif;
  }

  .header__contact-info {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header__nav-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    padding: 10px;
  }

  .header__contact-column {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .header__contact-line-1 {
    display: flex;
    font-size: 18px;
    color: #cf0000;
  }

  .header__contact-line-2,
  .header__contact-line-3 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #000;
  }
}
