/* About.css */
.about-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
  background-color: #f9f9f9;
  width: 100%;
  box-sizing: border-box;
}

.about-content {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: auto;
  max-width: 1200px;
  width: 100%;
}

.about__title {
  display: flex;
  font-family: 'oswald', sans-serif;
  justify-content: center;
  font-size: 50px;
  font-weight: 700;
  background: linear-gradient(#CE0000, #00158E);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 10px 0 20px 0;
  width: 100%;
  max-width: 560px;
}

.about__image {
  width: 350px;
  height: 350px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  margin: 0 60px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.about__paragraph {
  font-family: 'oswald', sans-serif;
  font-size: 16px;
  line-height: 1.6;
  background: linear-gradient(#CE0000, #00158E);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 0 15px 0;
  padding: 0 20px;
}

.about__info {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  max-width: 560px;
}

/* Media Queries for Tablet Interface */
@media (max-width: 1024px) {
  .about-content {
    padding: 0 20px;
  }

  .about__image {
    width: 300px;
    height: 300px;
    margin: 0 40px 0 0;
  }

  .about__title {
    font-size: 45px;
  }
}

/* Media Queries for Mobile Interface */
@media (max-width: 768px) {
  .about-container {
    padding: 30px 20px;
  }

  .about-content {
    flex-direction: column;
    padding: 0;
  }

  .about__title {
    font-size: 40px;
    margin: 20px 0;
  }

  .about__image {
    width: 250px;
    height: 250px;
    margin: 0 0 20px 0;
  }

  .about__paragraph {
    font-size: 14px;
    text-align: left;
  }

  .about__info {
    align-items: center;
  }
}

@media (max-width: 480px) {
  .about-container {
    padding: 20px 15px;
  }

  .about__title {
    font-size: 30px;
  }

  .about__image {
    width: 200px;
    height: 200px;
  }

  .about__paragraph {
    font-size: 12px;
    padding: 0 10px;
  }
}