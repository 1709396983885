/* Gallery.css */
.gallery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 40px 0;
  background-color: #d5d5d5;
  width: auto;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.gallery__title {
  font-size: 30px;
  color: #000;
  margin-bottom: 30px;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
}

.gallery-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.gallery__slideshow {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 60px;
  box-sizing: border-box;
}

.gallery__slide {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  z-index: 2;
  flex-wrap: wrap;
}

/* Update cards item styling within gallery */
.gallery__slide .cards__item {
  width: calc(50% - 10px);
  max-width: 360px;
  margin: 0;
  flex: 0 0 auto;
  position: relative;
  z-index: 3;
}

.gallery__slide .cards__image {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.gallery__image {
  width: 360px;
  height: 500px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.gallery__caption {
  margin-top: 10px;
  font-size: 16px;
  color: #000;
  text-align: center;
  font-family: "Oswald", sans-serif;
  font-weight: 600;
}

.gallery__arrow {
  background: none;
  border: none;
  font-size: 2.5rem;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  padding: 0;
  position: absolute;
  z-index: 10;
  color: #000;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  transition: opacity 0.3s;
}

.gallery__arrow:hover {
  opacity: 1;
}

.gallery__arrow--left {
  left: 10px;
}

.gallery__arrow--right {
  right: 10px;
}

.decorative-dots {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  position: absolute;
  left: 260px;
  top: 190px;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 0;
}

.decorative-dots-2 {
  height: 170px;
  width: 170px;
  border-radius: 50%;
  position: absolute;
  left: 70px;
  top: 40px;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 0;
}

.decorative-dots-3 {
  height: 400px;
  width: 400px;
  border-radius: 50%;
  position: absolute;
  right: -140px;
  top: -180px;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 0;
}

.decorative-dots-4 {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  position: absolute;
  left: 110px;
  top: 290px;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 0;
}

.decorative-dots-5 {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  position: absolute;
  right: 200px;
  top: 180px;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 0;
  overflow: hidden;
}

/* Media Queries */
@media (max-width: 768px) {
  .gallery__slideshow {
    padding: 0 50px;
  }

  .gallery__slide {
    flex-direction: column;
    gap: 15px;
  }

  .gallery__slide .cards__item {
    width: 100%;
    max-width: 300px;
  }

  .gallery__slide .cards__image {
    height: 300px;
  }

  .gallery__arrow {
    font-size: 2rem;
    width: 35px;
    height: 35px;
  }

  .gallery__arrow--left {
    left: 5px;
  }

  .gallery__arrow--right {
    right: 5px;
  }

  .gallery__title {
    font-size: 24px;
  }

  .decorative-dots,
  .decorative-dots-2,
  .decorative-dots-3,
  .decorative-dots-4,
  .decorative-dots-5 {
    display: none;
  }
}

@media (max-width: 480px) {
  .gallery__slideshow {
    padding: 0 40px;
  }

  .gallery__slide .cards__item {
    width: 100%;
    max-width: 250px;
  }

  .gallery__slide .cards__image {
    height: 250px;
  }

  .gallery__title {
    font-size: 20px;
  }

  .gallery__caption {
    font-size: 14px;
  }

  .gallery__arrow {
    font-size: 1.8rem;
    width: 30px;
    height: 30px;
  }
}
